import React from 'react'

const FormspreeContact = () => {
  return (
<>
      <h3>Activate Formspree</h3>
      
      <form
    action="https://formspree.io/f/mrgorqzw"
    method="POST"
  >
          <p>
            <label>
              <span>Your name:</span>
              <input type="text" required name="name" placeholder="NAME" />
            </label>
          </p>
          <p>
            <label>
              <span>Your email:</span>
              <input type="email" required name="_replyto" placeholder="EMAIL" />
            </label>
          </p>
          <p>
            <label>
              <span>Message:</span>
              <textarea name="message" placeholder="MESSAGE" rows="6" />
            </label>
          </p>
          <p>
            <button className="btn btn-outline-info" type="submit">SUBMIT</button>
          </p>
        </form>
  
</>
  )
}

export default FormspreeContact